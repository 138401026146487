import "../App.css";
import "./Imprint.css";

import { Nav } from "../Component/Nav";
import { Footer } from "../Component/Footer";
import { Box, Grid } from "@mui/material";

function DataPrivacy() {
  const includeContentVar = false;
  return (
    <div className="app">
      <Nav includeContent={includeContentVar} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          padding: "32px",
        }}
      >
        <Box sx={{ maxWidth: "800px" }}>
          <h1>Data Privacy Statement</h1>
          <p>
            At Mango Medical, we take the privacy of our users seriously. This
            Data Privacy Statement outlines how we collect, use, and protect any
            information you provide when using our website.
          </p>

          <h2>1. Collection of Information:</h2>
          <p>
            We do not collect any personal data from our users. The only
            information we may collect is the email address you voluntarily
            provide when contacting us through the provided contact information.
          </p>

          <h2>2. Use of Information:</h2>
          <p>
            The email address you provide will be used solely for the purpose of
            responding to your inquiries and providing the necessary assistance.
          </p>

          <h2>3. Cookies and Tracking Technologies:</h2>
          <p>
            We do not currently use any cookies or tracking technologies on our
            website.
          </p>

          <h2>4. Data Sharing:</h2>
          <p>
            We do not share any personal data collected from our users with
            third parties.
          </p>

          <h2>5. Data Security:</h2>
          <p>
            We have implemented appropriate security measures to protect any
            information you provide to us. However, please note that no method
            of transmission over the internet or electronic storage is
            completely secure, and we cannot guarantee absolute security.
          </p>

          <h2>6. Rights of Users:</h2>
          <p>
            As we do not collect personal data, there are no specific rights
            associated with data privacy. However, should you have any concerns
            or questions regarding our privacy practices, you may contact us
            using the provided contact information.
          </p>

          <h2>7. Age Restriction:</h2>
          <p>
            There is no age restriction for using our website. However, we
            encourage individuals under the age of 18 to seek parental consent
            before contacting us or providing any personal information.
          </p>

          <p>
            Please note that this Data Privacy Statement applies solely to
            information collected through our website and does not cover any
            third-party websites linked to or from our website.
          </p>

          <p>
            By using our website, you consent to the terms outlined in this Data
            Privacy Statement.
          </p>

          <p>
            If you have any questions or require further information, please
            feel free to contact us at info (at) mangomedical.com .
          </p>
        </Box>
      </Grid>
      <Footer />
    </div>
  );
}

export default DataPrivacy;
