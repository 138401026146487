import React from 'react';

const InfiniteWave = () => {
  const [viewBoxHeight, setViewBoxHeight] = React.useState(calculateViewBoxHeight());

  React.useEffect(() => {
    const handleResize = () => {
      setViewBoxHeight(calculateViewBoxHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function calculateViewBoxHeight() {
    const breakpoints = [
      { width: 768, divisor: 1, fixedHeight: 300 },
      { width: 1024, divisor: 4 },
      { width: 1300, divisor: 5 },
      { width: 1400, divisor: 6 },
      { width: 1500, divisor: 7 },
      { width: 1600, divisor: 8 },
      { width: 1700, divisor: 9 },
      { width: 1800, divisor: 9 },
      { width: 1900, divisor: 10 },
      { width: 2000, divisor: 10.5 },
      { width: 2100, divisor: 10.75 },
      { width: 2200, divisor: 11 },
      { width: 2300, divisor: 11.5 },
      { width: 2400, divisor: 12 },
      { width: 2500, divisor: 12.5 },
      { width: 2600, divisor: 13 },
      { width: 2700, divisor: 14 },
      { width: 2800, divisor: 15 },
      { width: 2900, divisor: 15.5 },
      { width: 3000, divisor: 16 },
      { width: 3100, divisor: 16.5 },
      { width: 3200, divisor: 17 },
      { width: Infinity, divisor: 20 }
    ];

    const { divisor, fixedHeight } = breakpoints.find(bp => window.innerWidth < bp.width);
    return fixedHeight || window.innerWidth / divisor;
  }

  return (
    <div className="w-full h-full">
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute w-full h-full"
        preserveAspectRatio="none"
        viewBox={`0 0 1440 ${viewBoxHeight}`}
      >
        <defs>
          <path id="wave" d="M0,150 C360,270 720,30 1080,150 C1440,270 1800,30 2160,150 V300 H0 V150 Z" />
        </defs>
        <g>
          <use href="#wave" fill="white" opacity="0.4">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="-2160 0"
              dur={`${Math.min(15, Math.max(10, 2160 / window.innerWidth * 19))}s`}
              repeatCount="indefinite"
            />
          </use>
          <use href="#wave" fill="white" opacity="0.4">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="2160 0"
              to="0 0"
              dur={`${Math.min(15, Math.max(10, 2160 / window.innerWidth * 19))}s`}
              repeatCount="indefinite"
            />
          </use>
        </g>
        <g>
          <use href="#wave" fill="white" opacity="0.6">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="-2160 0"
              dur={`${Math.min(9, Math.max(7, 2160 / window.innerWidth * 11))}s`}
              repeatCount="indefinite"
            />
          </use>
          <use href="#wave" fill="white" opacity="0.6">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="2160 0"
              to="0 0"
              dur={`${Math.min(9, Math.max(7, 2160 / window.innerWidth * 11))}s`}
              repeatCount="indefinite"
            />
          </use>
        </g>
        <g>
          <use href="#wave" fill="white" opacity="1">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="-2160 0"
              dur={`${Math.min(8, Math.max(6, 2160 / window.innerWidth * 9.6))}s`}
              repeatCount="indefinite"
            />
          </use>
          <use href="#wave" fill="white" opacity="1">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="2160 0"
              to="0 0"
              dur={`${Math.min(8, Math.max(6, 2160 / window.innerWidth * 9.6))}s`}
              repeatCount="indefinite"
            />
          </use>
        </g>
      </svg>
    </div>
  );
};

export default InfiniteWave;