import React, { useState, useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import navimg from "../Img/svg-logo.png";
import desknavimg from "../Img/svg-logodesk.svg";
import HeroSkull from "../Img/skull_small.png";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfiniteWave from "./InfiniteWave";

import Container from "@mui/material/Container";
import "./Nav.css";

export const Nav = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('submitting');

    try {
      const formData = new FormData(e.target);
      const response = await fetch('/', {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString()
      });

      if (response.ok) {
        setSubmitStatus('success');
        setEmail('');
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
    }
  };

  const includeContent = props.includeContent;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const drawerWidth = 240;

  const drawer = (
    <Box
      className="mediaNavbar"
      onClick={handleDrawerToggle}
      sx={{
        width: '200px',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <Typography variant="h6" sx={{ my: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img className="logo_img" src={navimg} style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          display: 'block',
          paddingLeft: '30px',
        }}
        alt="Mango Medical Logo"
      />
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton href="/" sx={{ textAlign: "center" }}>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            href="mailto: info@mangomedical.io"
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary="Contact" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <div className="top_navBox" style={{
        height: includeContent ? (isMobile ? 'auto' : '900px') : '300px',      
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: isMobile ? '150px' : '0',
      }}>
        <div style={{
          position: 'absolute',
          bottom: -10,
          left: 0,
          right: 0,
          zIndex: 0
        }}>
          <InfiniteWave />
        </div>
        <Box style={{ position: 'relative', zIndex: 1 }}>
          <CssBaseline />
          <Container maxWidth="xl">
            <AppBar
              component="nav"
              color="transparent"
              sx={{ boxShadow: 0, pt: 2, position: "static" }}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  href="/"
                  sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                >
                  <a href="/">
                    <img className="logo_img" src={desknavimg} />
                  </a>
                </Typography>
                <Box
                  className="navmenu_box"
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    marginLeft: "auto",
                  }}
                >
                  <Button
                    href="https://beta.mangomedical.io/"
                    sx={{
                      color: "#fff",
                      fontFamily: "poppins",
                      textTransform: "capitalize",
                      fontWeight: "600",
                    }}
                  >
                    Beta Login
                  </Button>

                </Box>
              </Toolbar>
            </AppBar>
            <Box component="nav">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
            {includeContent && (
              <div
                className="navcon"
                style={{
                  display: "flex",
                  paddingTop: "30px"
                }}
              >
                <Box
                  sx={{ width: isMobile ? "100%" : "55%", paddingTop: "60px" }}
                  className="navtopcontanct"
                >
                  <Typography
                    variant="h1"
                    component="h3"
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 900,
                      fontSize: isMobile ? "36px" : "46px",
                      lineHeight: "100%",
                      letterSpacing: "0em",
                      textTransform: "uppercase",
                      color: "#FFFFFF",
                    }}
                    className="navh3"
                  >
                    The next Generation <br />
                    of Fracture Surgery
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "16px",
                      lineHeight: "120%",
                      marginTop: "15px",
                      letterSpacing: " -0.02em",
                      color: "#FFFFFF",
                    }}
                    className="navpara"
                  >
                    Our AI-based software produces accurate, rapid, and
                    intuitive preoperative plans, revolutionizing orthopedic
                    care for surgeons and patients.
                  </Typography>

                  <div style={{ textAlign: "center", marginTop: isMobile ? "40px" : "50px"}}>
                    <form 
                      name="email-subscription"
                      method="POST"
                      data-netlify="true"
                      onSubmit={handleSubmit}
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <input type="hidden" name="form-name" value="email-subscription" />
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        borderRadius: "8px",
                        overflow: "hidden",
                        width: "100%",
                        maxWidth: "400px",
                        border: "1px solid #C6C6C6",
                      }}>
                        <TextField
                          placeholder="Enter your email"
                          variant="outlined"
                          name="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          sx={{
                            flexGrow: 1,
                            '& .MuiOutlinedInput-root': {
                              padding: "0px 4px",
                              '& fieldset': {
                                border: 'none',
                              },
                              '& input': {
                                color: "#FFF",
                              },
                              '&:hover fieldset': {
                                borderColor: "#4D78E7",
                              },
                            },
                          }}
                          InputLabelProps={{ style: { color: '#FFF' } }}
                          InputProps={{
                            style: {
                              color: '#FFF',
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    color: "#FFF",
                                    backgroundColor: "rgba(200, 200, 200, 0.3)",
                                    borderRadius: "8px 8px 8px 8px",
                                    padding: "24px 12px",
                                    height: "100%",
                                    textTransform: "none",
                                    boxShadow: "none",
                                    '&:hover': {
                                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                                      boxShadow: "none",
                                    }
                                  }}
                                  endIcon={<ArrowForwardIcon />}
                                >
                                  Get updates
                                </Button>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                    </form>
                    {submitStatus === 'success' && <p style={{ color: 'green', marginTop: '10px' }}>Thank you for subscribing!</p>}
                    {submitStatus === 'error' && <p style={{ color: 'red', marginTop: '10px' }}>An error occurred. Please try again.</p>}
                    </div>
                </Box>
                
                <Box sx={{ maxWidth: isMobile ? "100%" : "60%", marginTop: isMobile ? "10px" : "0" }}>
                  <img className="skullimg" src={HeroSkull} style={{ width: '100%', height: 'auto' }} />
                </Box>
              </div>
            )}
          </Container>
        </Box>
      </div>
    </>
  );
};
