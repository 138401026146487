import "./App.css";
import { Footer } from "./Component/Footer";
import { Nav } from "./Component/Nav";
function App() {
  const includeContentVar = true;
  return (
    <div className="app">
      <Nav includeContent={includeContentVar} />
      <Footer />
    </div>
  );
}

export default App;
